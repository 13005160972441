import React from 'react'

import { HTMLHead } from 'components/HTMLHead'
import type { AppProps } from 'next/app'
import { GlobalStyles } from 'styles'

import { ThemeProvider } from '@emotion/react'
import { CssReset } from '@inspectornexus/components'
import { theme } from '@inspectornexus/theme'

const MyApp = ({ Component, pageProps }: AppProps) => (
  <>
    <HTMLHead />
    <CssReset />
    <GlobalStyles />
    <ThemeProvider theme={theme}>
      <Component {...pageProps} />
    </ThemeProvider>
  </>
)

export default MyApp
