import { css } from '@emotion/react'

export const fontFaces = css`
  @font-face {
    font-family: 'SF Pro Display';
    font-weight: bold;
    src: url('fonts/SF-Pro-Display-Bold.otf') format('opentype');
  }
  @font-face {
    font-family: 'SF Pro Display';
    src: url('fonts/SF-Pro-Display-Regular.otf') format('opentype');
  }
  @font-face {
    font-family: 'SF Pro Text';
    font-weight: bold;
    src: url('fonts/SF-Pro-Text-Bold.otf') format('opentype');
  }
  @font-face {
    font-family: 'SF Pro Text';
    src: url('fonts/SF-Pro-Text-Regular.otf') format('opentype');
  }
  /* @font-face {
    font-family: 'Segoe UI';
    font-weight: bold;
    src: url('fonts/Segoe-UI-Bold.ttf');
  }
  @font-face {
    font-family: 'Segoe UI';
    src: url('fonts/Segoe-UI-Regular.ttf');
  }
  @font-face {
    font-family: 'Roboto';
    font-weight: bold;
    src: url('fonts/Roboto-Bold.ttf');
  }
  @font-face {
    font-family: 'Roboto';
    src: url('fonts/Roboto-Regular.ttf');
  } */
`
